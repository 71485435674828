import {
  CatalogProcessor,
  CatalogProcessorCache,
  CatalogProcessorEmit,
} from '@backstage/plugin-catalog-node';
import { LocationSpec } from '@backstage/plugin-catalog-common';
import { getCompoundEntityRef, Entity } from '@backstage/catalog-model';
import { AuthService, LoggerService } from '@backstage/backend-plugin-api';
import { getGitHubDetailsFromEntity, emitRelationships } from './utils';

const ALLOWED_KINDS = ['Component'];
const CACHE_KEY = 'v1';

type CacheItem = {
  timestamp: string;
  connections: string[];
};

export class GitHubCloudAccountRelationshipProcessor
  implements CatalogProcessor
{
  private readonly logger: LoggerService;
  private readonly auth: AuthService;
  private readonly backendUrl: string;

  constructor(options: {
    logger: LoggerService;
    auth: AuthService;
    backendUrl: string;
  }) {
    this.logger = options.logger;
    this.auth = options.auth;
    this.backendUrl = options.backendUrl;
  }

  getProcessorName(): string {
    return 'GitHubCloudAccountRelationshipProcessor';
  }

  async postProcessEntity(
    entity: Entity,
    _: LocationSpec,
    emit: CatalogProcessorEmit,
    cache: CatalogProcessorCache,
  ): Promise<Entity> {
    if (!ALLOWED_KINDS.includes(entity.kind)) {
      return entity;
    }

    // get the org/repo from the entity
    const gitHubDetails = getGitHubDetailsFromEntity(entity);

    if (!gitHubDetails) {
      return entity;
    }

    // check if we have cached the connections for this entity
    const cacheItem = await cache.get<CacheItem>(CACHE_KEY);

    // check if cache is over 2 hours old, if not use the cache
    if (
      cacheItem &&
      new Date(cacheItem.timestamp).getTime() >
        new Date().getTime() - 1000 * 60 * 60 * 2
    ) {
      this.emitRelationshipsForConnections(emit, entity, cacheItem.connections);
      return entity;
    }

    try {
      const connections = await this.getConnections(
        gitHubDetails.org,
        gitHubDetails.repo,
      );

      this.logger.debug(
        `Found ${connections.length} connections for ${gitHubDetails.org}/${gitHubDetails.repo}`,
      );

      // cache the connections found so we don't fetch them for some time
      await cache.set(CACHE_KEY, {
        timestamp: new Date().toISOString(),
        connections,
      });

      this.emitRelationshipsForConnections(emit, entity, connections);
    } catch (error) {
      this.logger.warn(
        `Failed to get cloud account connections for ${gitHubDetails.org}/${gitHubDetails.repo}: ${error}`,
      );
      return entity;
    }

    return entity;
  }

  private emitRelationshipsForConnections(
    emit: CatalogProcessorEmit,
    entity: Entity,
    connections: string[],
  ) {
    for (const accountID of connections) {
      emitRelationships(getCompoundEntityRef(entity), emit, accountID, {
        defaultKind: 'Resource',
        defaultNamespace: getCompoundEntityRef(entity).namespace,
      });
    }
  }

  private async getConnections(org: string, repo: string): Promise<string[]> {
    // fetch any cloud account oidc connections
    const { token } = await this.auth.getPluginRequestToken({
      onBehalfOf: await this.auth.getOwnServiceCredentials(),
      targetPluginId: 'cloud-accounts',
    });

    const response = await fetch(
      `${this.backendUrl}/api/cloud-accounts/${org}/${repo}/oidc-connections`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error(
        `Couldn't fetch cloud account connections: ${response.status}`,
      );
    }

    const connnectionsResponse: { connections: string[] } =
      await response.json();

    return connnectionsResponse.connections;
  }
}
